import React from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/functions';

const app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
});
//エミュレータ定義
//  localhostからの接続の場合はエミュレータを使用する
if (window.location.hostname === 'localhost') {
    firebase.functions().useEmulator('localhost', 5001);
    firebase.auth().useEmulator('http://localhost:9099');
}

export async function loginWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    await app.auth().signInWithRedirect(provider);
}

export async function logout() {
    await app.auth().signOut();
}

export function onAuthStateChanged(callback: (user: firebase.User | null) => void) {
    app.auth().onAuthStateChanged(callback);
}

export const FirebaseContext = React.createContext<firebase.User | null>(null);

export async function getUserInfo(): Promise<any> {
    const getUserInfo = firebase.functions().httpsCallable('get_user_info');
    const result = await getUserInfo({});
    return result.data;
}

export async function listPointOperations(): Promise<any> {
    const listPointOperations = firebase.functions().httpsCallable('list_point_operations');
    const result = await listPointOperations({});
    return result.data;
}

export async function getPointOperation(id: string): Promise<any> {
    const getPointOperation = firebase.functions().httpsCallable('get_point_operation');
    const result = await getPointOperation({id: id});
    return result.data;
}

export async function deployByUser(id: string, deploy: string): Promise<any> {
    const deployByUser = firebase.functions().httpsCallable('deploy_by_user');
    const result  = await deployByUser({
        point_operation_id: id,
        deploy: deploy
    });
    return result.data;
}